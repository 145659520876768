import type { ReactNode } from 'react'
import React from 'react'
import { Provider } from 'mobx-react'
import mobxStores from './stores/globalStore'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import MyEmpty from '@/components/Empty'
import { history, useModel } from 'umi'
import { getPortalCodeFromUrl } from './utils/getPortalCodeFromUrl'

const stores = mobxStores()

const MobxProvider = (props: any) => (
    <Provider {...stores} {...props}>
        <ConfigProvider locale={zhCN} renderEmpty={MyEmpty} {...props} />
    </Provider>
)

export function rootContainer(container: ReactNode[], opts: any) {
    return React.createElement(MobxProvider, opts, container)
}

export function useQiankunStateForSlave(): any {
    const {
        userStore: {
            getOrganizationList,
            setSelectOrganization,
            selectedOrganization,
            setSelectIdentity,
            bindMenuRouteChangeCallback,
        },
    } = stores || {}

    // @ts-ignore
    const masterModel = useModel('@@qiankunStateFromMaster')
    const { gatewaySiteStore } = masterModel || {}
    const { portalData } = gatewaySiteStore || {}

    const getCurrentOrganization = () => {
        const {
            userStore: { selectedOrganization: selectedOrganizationCode },
        } = stores || {}
        return selectedOrganizationCode
    }

    // TODO 兼容用户中心的机构中心
    const updateCurrentOrganization = (code: string) => {
        getOrganizationList().then(() => {
            code ? setSelectOrganization(code) : ''
        })
    }

    return {
        tag: 'workbench',
        // TODO 兼容用户中心的机构中心
        currentOrganization: selectedOrganization,
        // TODO 兼容用户中心的机构中心
        getCurrentOrganization: getCurrentOrganization,
        // TODO 兼容用户中心的机构中心
        updateCurrentOrganization,
        masterStore: stores,
        masterHistory: history,
        getOrganizationCode: getPortalCodeFromUrl,
        portalData: portalData,
        // 选中当前身份，给用户中心专题登录页使用
        setSelectIdentity,
        //订阅的入口
        bindMenuRouteChangeCallback,
    }
}
