/***
 * 回到登录方法
 * 项目统一使用
 */

import { getLocalStorage } from '@/storage'
import { findSiteData, shouldLogin } from '@wotu/wotu-components'
import { getPortalCodeFromUrl } from './getPortalCodeFromUrl'

const doToLogin = (identity?: number) => {
    const { siteData } = getLocalStorage('SITE_STORE') || {}
    const alias = findSiteData(siteData, 'alias', { findKey: 'baseInfo' }) || ''
    const pcDomain = findSiteData(siteData, 'pcDomain', { findKey: 'baseInfo' }) || ''
    const portalCode = getPortalCodeFromUrl({ isGetDomain: true }) || ''
    shouldLogin({
        alias,
        portalCode,
        storageKey: 'workSiteStore',
        special: [
            {
                alias: 'jiangsu',
                url: 'https://rs.jshrss.jiangsu.gov.cn/web/login?appId=202209160001&returnUrl=https%3A%2F%2Fwww.jsgjkt.com%2Fleaf6-zxpx%2F%2F%23%2Fhome',
            },
            {
                alias: 'hzxgx',
                url: pcDomain,
            },
        ],
        identity,
    })
}

export default doToLogin
