// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/fe-business-pc_STLT/node_modules/.pnpm/@umijs+runtime@3.5.20_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/403",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403__index' */'@/pages/403/index.tsx'), loading: LoadingComponent}),
        "title": "403"
      },
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent}),
        "title": "未找到页面"
      },
      {
        "path": "/content/graphic/add-graphic",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__content__graphic__add-graphic__index' */'@/pages/content/graphic/add-graphic/index.tsx'), loading: LoadingComponent}),
        "title": "新增图文"
      },
      {
        "path": "/content/graphic",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__content__graphic__index' */'@/pages/content/graphic/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/content/graphic-classific",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__content__graphic-classific__index' */'@/pages/content/graphic-classific/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/content/graphic-classific/store",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__content__graphic-classific__store' */'@/pages/content/graphic-classific/store.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/info",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__info__index' */'@/pages/gateway/info/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/pc-tab",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__pc-tab__index' */'@/pages/gateway/pc-tab/index.tsx'), loading: LoadingComponent}),
        "title": "导航管理"
      },
      {
        "path": "/gateway/pc-view",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__pc-view__index' */'@/pages/gateway/pc-view/index.tsx'), loading: LoadingComponent}),
        "title": "pc微页面预览"
      },
      {
        "path": "/gateway/pc-web/create",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__pc-web__create__index' */'@/pages/gateway/pc-web/create/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/pc-web",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__pc-web__index' */'@/pages/gateway/pc-web/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/tab/hooks",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__tab__hooks' */'@/pages/gateway/tab/hooks.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/tab",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__tab__index' */'@/pages/gateway/tab/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/web/create",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__web__create__index' */'@/pages/gateway/web/create/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/gateway/web",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__gateway__web__index' */'@/pages/gateway/web/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/transaction/order/detail",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__order__detail__index' */'@/pages/transaction/order/detail/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/transaction/order",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__order__index' */'@/pages/transaction/order/index.tsx'), loading: LoadingComponent}),
        "title": "订单管理"
      },
      {
        "path": "/transaction/refund",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transaction__refund__index' */'@/pages/transaction/refund/index.tsx'), loading: LoadingComponent}),
        "title": "售后管理"
      },
      {
        "path": "/workbench/DataOverview",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__DataOverview' */'@/pages/workbench/DataOverview.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/workbench",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__index' */'@/pages/workbench/index.tsx'), loading: LoadingComponent}),
        "title": "工作台"
      },
      {
        "path": "/case",
        "routes": [
          {
            "path": "/case/detail",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__case__detail__index' */'@/pages/case/detail/index.tsx'), loading: LoadingComponent}),
            "title": "案例详情"
          },
          {
            "path": "/case/list",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__case__list__index' */'@/pages/case/list/index.tsx'), loading: LoadingComponent}),
            "title": "标杆案例"
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__case___layout' */'@/pages/case/_layout.tsx'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
